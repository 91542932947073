var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "1280.000000pt",
        height: "1280.000000pt",
        viewBox: "0 0 1280.000000 1280.000000",
        preserveAspectRatio: "xMidYMid meet",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            transform:
              "translate(0.000000,1280.000000) scale(0.100000,-0.100000)",
            fill: "currentColor",
            stroke: "none",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M3226 12678 c-3 -68 -8 -391 -11 -718 -3 -327 -8 -751 -12 -942 l-6 -348 3737 0 3736 0 0 -4270 0 -4270 -3735 0 -3735 0 0 -1065 0 -1065 4800 0 4800 0 0 6400 0 6400 -4784 0 -4784 0 -6 -122z",
            },
          }),
          _c("path", {
            attrs: {
              d: "M4260 8535 l1065 -1065 -2662 0 -2663 0 0 -1070 0 -1070 2668 0 2667 0 -1065 -1065 -1065 -1065 1600 0 1600 0 1598 1598 1597 1597 -1602 1603 -1603 1602 -1600 0 -1600 0 1065 -1065z",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }